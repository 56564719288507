import { StatusMessage } from '../../@types/liveDealer';
import { useLiveWaitConfig } from 'hooks/useLiveWaitConfig';
import useLocalStorage from 'hooks/useLocalStorage';
import { useGameCoordinatorContext } from 'pages/dashboard/game-coordinator/GameCoordinatorStateProvider';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import {
  getBettingRoundFromState,
  getDealingRoundFromState,
  getRevealRoundFromState,
  getSqueezeRoundFromState,
} from 'utils/gameStateParsing';
import { handleInactivity } from 'utils/handleInactivity';
import { LocalStorageKeys } from 'utils/localStorage';

interface Props {
  children: React.ReactElement;
}

export interface LiveDealerHelperContextValue {
  stateMessage?: StatusMessage;
  countdownSecondsLeft: number;
  activePosition: number;
}

const initialState: LiveDealerHelperContextValue = {
  stateMessage: undefined,
  countdownSecondsLeft: 0,
  activePosition: 0,
};

const LiveDealerHelperContext = createContext<LiveDealerHelperContextValue>(initialState);

export const LiveDealerHelperStateProvider = ({ children }: Props) => {
  const liveWaitConfig = useLiveWaitConfig();
  const {
    gssGameState,
    gssBettingRoundData,
    gssHandNo,
    gssNextDealPosition,
    gssNextRevealPosition,
  } = useGameCoordinatorContext();

  const [betRoundEndTime, setBetRoundEndTime] = useState<string>();
  const [countdownSecondsLeft, setCountdownSecondsLeft] = useState<number>(0);
  const [stateMessage, setStateMessage] = useState<StatusMessage>();

  const [lastSqueezeRound, setLastSqueezeRound] = useLocalStorage(
    LocalStorageKeys.LDH_LAST_SQUEEZE_ROUND,
    0
  );
  const [nextSqueezeRound, setNextSqueezeRound] = useLocalStorage(
    LocalStorageKeys.LDH_NEXT_SQUEEZE_ROUND,
    0
  );
  const [lastBettingRound, setLastBettingRound] = useLocalStorage(
    LocalStorageKeys.LDH_LAST_BET_ROUND,
    0
  );
  const [lastHandNo, setLastHandNo] = useLocalStorage(LocalStorageKeys.LDH_LAST_HAND_NO, 0);
  const [hasIntroRun, setHasIntroRun] = useLocalStorage(LocalStorageKeys.LDH_HAS_INTRO_RUN, false);
  const [hasOutroRun, setHasOutroRun] = useLocalStorage(LocalStorageKeys.LDH_HAS_OUTRO_RUN, false);
  const [timerHandle, setTimerHandle] = useState<NodeJS.Timeout | null>(null);

  const fourthBettingRoundFinished =
    gssGameState === 'BET_ROUND_FINISHED' && lastBettingRound === 4;

  const bettingRound = getBettingRoundFromState(gssGameState);
  const expectedSqueezeRound = getSqueezeRoundFromState(gssGameState, fourthBettingRoundFinished);
  const revealingRound = getRevealRoundFromState(gssGameState);
  const dealingRound = getDealingRoundFromState(gssGameState);

  const isEventFinished = gssGameState === 'EVENT_FINISHED' || gssGameState === 'GAME_CANCELLED';
  const isSqueezeState = gssGameState === 'READY_FOR_SQUEEZE';
  const isWaitForBettingRound =
    gssGameState === 'READY_FOR_BET_ROUND' ||
    gssGameState === 'BANKER_SET' ||
    gssGameState === 'ODDS_GETTING';
  const isIntro = isWaitForBettingRound && lastBettingRound === 0;
  const isOutro = gssGameState === 'EVENT_FINISHED';
  const isNewEvent = gssGameState === 'NEW_SHOE';
  const isNewHand = gssGameState === 'HAND_STARTED';
  const isFirstHand = isNewEvent && gssHandNo === 1;
  const isHandOver = gssGameState === 'HAND_FINISHED';
  const isRevealingState = gssGameState === 'READY_FOR_REVEAL' || revealingRound > 0;
  const isDealingState = gssGameState === 'READY_FOR_DEAL' || dealingRound > 0;

  console.log(
    'update: ',
    JSON.stringify(
      {
        gssGameState,
        betRoundEndTime,
        countdownSecondsLeft,
        stateMessage,
        expectedSqueezeRound,
        nextSqueezeRound,
        lastSqueezeRound,
        lastHandNo,
        hasIntroRun,
        hasOutroRun,
        fourthBettingRoundFinished,
        isSqueezeState,
        isWaitForBettingRound,
        isIntro,
        isOutro,
        isNewEvent,
        isNewHand,
        isFirstHand,
        isHandOver,
        bettingRound,
        lastBettingRound,
        isDealingState,
        gssNextDealPosition,
        dealingRound,
        isRevealingState,
        gssNextRevealPosition,
        revealingRound,
      },
      null,
      2
    )
  );

  useEffect(() => {
    handleInactivity(true);
    return () => handleInactivity();
  }, []);

  useEffect(() => {
    if (isNewEvent) {
      setHasIntroRun(false);
      setHasOutroRun(false);
      setLastHandNo(0);
      setLastBettingRound(0);
      setLastSqueezeRound(0);
      setNextSqueezeRound(0);
    }
  }, [
    isNewEvent,
    setHasIntroRun,
    setHasOutroRun,
    setLastHandNo,
    setLastBettingRound,
    setLastSqueezeRound,
    setNextSqueezeRound,
  ]);

  useEffect(() => {
    if (isHandOver && lastBettingRound > 0) {
      setLastBettingRound(0);
      setLastSqueezeRound(0);
      setNextSqueezeRound(0);
    }
  }, [isHandOver, lastBettingRound, setLastBettingRound, setLastSqueezeRound, setNextSqueezeRound]);

  useEffect(() => {
    if (bettingRound <= lastBettingRound) return;
    setLastBettingRound(bettingRound);
  }, [bettingRound, lastBettingRound, setLastBettingRound]);

  useEffect(() => {
    if (expectedSqueezeRound <= nextSqueezeRound) return;
    setNextSqueezeRound(expectedSqueezeRound);
  }, [nextSqueezeRound, expectedSqueezeRound, setNextSqueezeRound]);

  useEffect(() => {
    if (!gssGameState) return;
    const getStateMessage = (): StatusMessage => {
      if (gssGameState === 'NEW_SHOE' || gssGameState === 'BANKER_DETERMINATION' || isFirstHand)
        return { message: 'PERFORM BANKER DETERMINATION', color: 'green', showLegend: false };

      if (isIntro && countdownSecondsLeft)
        return {
          message: 'WAIT FOR INTRO',
          color: 'red',
          showLegend: false,
        };

      if (isOutro && countdownSecondsLeft)
        return {
          message: 'WAIT FOR OUTRO',
          color: 'red',
          showLegend: false,
        };

      if (isWaitForBettingRound)
        return { message: 'WAIT FOR BET ROUND TO START', color: 'red', showLegend: false };

      if (isRevealingState)
        return {
          message: 'REVEAL FACE DOWN CARDS',
          color: 'green',
          showLegend: false,
        };

      if (isSqueezeState)
        return {
          message: 'WAIT FOR SQUEEZE',
          color: 'red',
          showLegend: false,
        };

      if (isHandOver) return { message: 'WAIT FOR HAND TO START', color: 'red', showLegend: false };

      if (isEventFinished) return { message: 'EVENT IS FINISHED', color: 'red', showLegend: false };

      if (countdownSecondsLeft)
        return {
          message: 'TIME REMAINING',
          color: bettingRound > 0 ? 'red' : 'green',
          showLegend: false,
        };

      console.log(`default message: gss state ${gssGameState}`);
      return { message: '', color: 'green', showLegend: true };
    };
    const stateMessage = getStateMessage();
    setStateMessage(stateMessage);
    handleInactivity(); // TODO: Figure out what caused the previous fix to stop working
  }, [
    bettingRound,
    countdownSecondsLeft,
    gssGameState,
    isFirstHand,
    isHandOver,
    isSqueezeState,
    isWaitForBettingRound,
    isIntro,
    isOutro,
    isEventFinished,
    isRevealingState,
  ]);

  useEffect(() => {
    if (gssBettingRoundData) {
      setBetRoundEndTime(gssBettingRoundData.endBettingRound);
    }
  }, [gssBettingRoundData]);

  const runTimer = useCallback(
    (timerSeconds) => {
      const secondsLeft = timerSeconds - 1;
      setCountdownSecondsLeft(secondsLeft);
      if (secondsLeft > 0) {
        setTimerHandle(setTimeout(() => runTimer(secondsLeft), 1000));
      } else {
        setCountdownSecondsLeft(0);
      }
    },
    [setTimerHandle]
  );

  const startTimer = useCallback(
    (timerSeconds) => {
      if (timerHandle) {
        clearTimeout(timerHandle);
      }
      setCountdownSecondsLeft(timerSeconds);
      setTimeout(() => runTimer(timerSeconds), 1000);
    },
    [timerHandle, runTimer]
  );

  useEffect(() => {
    if (countdownSecondsLeft > 0 || !betRoundEndTime) return;

    const betRoundEnd = new Date(betRoundEndTime);
    const secondsLeft = Math.floor(
      Math.max((betRoundEnd.getTime() - new Date().getTime()) / 1000, 0)
    );

    startTimer(secondsLeft);
  }, [betRoundEndTime, countdownSecondsLeft, startTimer]);

  useEffect(() => {
    if (!isSqueezeState || nextSqueezeRound <= lastSqueezeRound) return;

    setLastSqueezeRound(nextSqueezeRound);
    const waitTimes = liveWaitConfig?.squeezeRoundDuration as number[] | undefined;

    const secondsLeft = waitTimes?.[nextSqueezeRound - 1] ?? 10;
    console.log('useEffect wait for squeeze, seconds = ', secondsLeft);

    startTimer(secondsLeft);
  }, [
    startTimer,
    liveWaitConfig,
    isSqueezeState,
    lastSqueezeRound,
    nextSqueezeRound,
    setLastSqueezeRound,
  ]);

  useEffect(() => {
    const currentHandNo = gssHandNo ?? 0;
    if (!isHandOver || currentHandNo <= lastHandNo) return;

    setLastHandNo(currentHandNo);
    const secondsLeft = liveWaitConfig?.handStartDelay ?? 60;
    console.log('useEffect wait for hand start, seconds = ', secondsLeft);

    startTimer(secondsLeft);
  }, [startTimer, liveWaitConfig, lastHandNo, gssHandNo, setLastHandNo, isHandOver]);

  useEffect(() => {
    if (!isIntro || hasIntroRun) return;

    setHasIntroRun(true);
    const waitTime = liveWaitConfig?.introDuration ?? 30;
    console.log('useEffect wait for intro, seconds = ', waitTime);

    startTimer(waitTime);
  }, [startTimer, liveWaitConfig, hasIntroRun, isIntro, setHasIntroRun]);

  useEffect(() => {
    if (!isOutro || hasOutroRun) return;

    setHasOutroRun(true);
    const waitTime = liveWaitConfig?.outroDuration ?? 30;
    console.log('useEffect wait for outro, seconds = ', waitTime);

    startTimer(waitTime);
  }, [startTimer, liveWaitConfig, hasOutroRun, isOutro, setHasOutroRun]);

  const gssActivePosition =
    (isRevealingState ? gssNextRevealPosition : isDealingState ? gssNextDealPosition : 0) ?? 0;

  return (
    <LiveDealerHelperContext.Provider
      value={{
        stateMessage,
        countdownSecondsLeft,
        activePosition: gssActivePosition,
      }}
    >
      {children}
    </LiveDealerHelperContext.Provider>
  );
};

export const useLiveDealerHelperContext = () => useContext(LiveDealerHelperContext);
